import { useEffect, useRef, useState } from "react";
import {
  AmountBox,
  DropDownBox,
  FullButton,
  MiniAmountBox,
  SelectorBoxes,
  ToolTipContainer,
  TradeDropDown,
} from "../../../styled/input/Input";
import { ClickAwayListener } from "@mui/material";
import {
  formatter,
  formatterZero,
  toFixedIfNecessary,
} from "../../../utils/utils";
import { doc, increment, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import Toast from "../../../hooks/Toast";
import CircularLoader from "../../../styled/loaders/CircularLoader";
import { Navigate, useNavigate } from "react-router-dom";

const Calculator = ({ pools, crypto, fiat, prices, action, user }) => {
  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [miner, setMiner] = useState(pools[0]);

  // useEffect(() => {}, [console.log(pools, "pools")]);

  const navigate = useNavigate();

  //   useEffect(() => {
  //     console.log(pools);
  //   }, []);

  // amount
  const [amount, setAmount] = useState("");
  function handleAmount(e) {
    setCompiledTotal(null);
    setAmount(0);

    const { value } = e.target;

    if (value) {
      setAmount(Number(value));
    } else {
      setAmount("");
    }
  }
  const [amountError, setAmountError] = useState(false);
  const amountRef = useRef();

  const [selectedAsset, setSelectedAsset] = useState(undefined);

  // roi & compiled
  const [compiledTotal, setCompiledTotal] = useState(0);
  const [showCompiledToolTip, setShowCompiledToolTip] = useState(false);
  const compiledToolTipMessage = useState(
    `This is the amount you will earn after the mining session completes, plus your initial amount`
  );

  const [duration, setDuration] = useState(miner?.duration);

  const [selectedDuration, setSelectedDuration] = useState(1);
  function handleDuration(e) {
    setCompiledTotal(null);
    // setAmount(0);

    // if (amountRef) {
    //   amountRef.current.value = "";
    // }

    const { value } = e.target;

    if (value) {
      setSelectedDuration(value);
    } else {
      setSelectedDuration(1);
    }
  }

  // duration
  const [maxiDaysArr, setMaxiDaysArr] = useState([]);
  useEffect(() => {
    let arr = [];
    let num = duration;

    for (let i = 0; i < num; i++) {
      const value = i + 1;
      arr.push(value);
    }
    setMaxiDaysArr(arr);
  }, [duration, miner, selectedDuration]);

  const [roi, setRoi] = useState(miner?.roi);

  useEffect(() => {
    if (roi && duration && amount) {
      setCompiledTotal(
        (Number((amount / 100) * roi + amount) * selectedDuration).toFixed(3)
      );
    } else {
      setCompiledTotal(undefined);
    }
  }, [roi, duration, amount, selectedDuration]);

  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipMessage, setToolTipMessage] = useState("");

  const [showRoiTooltip, setShowRoiTooltip] = useState(false);
  const roiTooltipMessage = "This is the total % of your mining earnings";

  function handleSelectMiner(name) {
    setCompiledTotal(null);
    setAmount(0);

    if (amountRef) {
      amountRef.current.value = "";
    }

    pools?.forEach((pool) => {
      if (pool.miner === name) {
        setMiner(pool);
        console.log(pool);
      }
    });
  }

  useEffect(() => {
    setDuration(miner.duration);

    let arr = [];
    let num = duration;

    for (let i = 0; i < num; i++) {
      const value = i + 1;
      arr.push(value);
    }

    setMaxiDaysArr(arr);

    setRoi(miner.roi);
  }, [miner, roi, amount, selectedDuration]);

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {/* Miner */}
      <DropDownBox className="type_select">
        <div className="wrapper">
          <p className="label">Miner:</p>
          <span className="content">
            <select
              name="options"
              onChange={(e) => handleSelectMiner(e.target.value)}
            >
              {pools?.map((pool) => (
                <option value={pool?.miner} key={pool?.miner}>
                  {pool?.miner}
                </option>
              ))}
            </select>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="#5C6175"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </DropDownBox>

      {/* pool (dropdown) */}

      {/* Amount */}
      <AmountBox
        className={amountError ? "amount_box error" : "amount_box"}
        style={{ position: "relative" }}
      >
        <div className="label">
          <p>Amount:</p>
          <img
            src="./assets/misc/info.svg"
            alt=""
            className="error_inform"
            id="popcorn"
            onClick={() => setShowToolTip(!showToolTip)}
          />
          {showToolTip && (
            <ClickAwayListener onClickAway={() => setShowToolTip(false)}>
              <div className="tooltip" id="tooltip">
                {tooltipMessage}
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div className="wrapper">
          <input
            type="number"
            placeholder={miner.minimum}
            onChange={handleAmount}
            ref={amountRef}
          />

          <span className="asset">
            <span>
              <img src={`./asseticons/${miner?.symbol}.svg`} alt="" />
              <p>{miner?.symbol}</p>
            </span>
          </span>
        </div>

        <div className="captions">
          {selectedAsset && (
            <span>
              <p className="caption">Current balance</p>
              <p className="value">
                {action === "Buy"
                  ? fiat?.value + " USD"
                  : selectedAsset?.value + " " + selectedAsset?.asset}
              </p>
            </span>
          )}

          {selectedAsset && (
            <span>
              <p className="caption">Current {selectedAsset.asset} price</p>
              <p className="value" style={{ color: "#5BDE4C" }}>
                {formatter.format(prices[selectedAsset?.asset])}
              </p>
            </span>
          )}

          {amount > 0 && selectedAsset && (
            <span>
              <p className="caption">Total in USD</p>
              <p className="value" style={{ color: "#5BDE4C" }}>
                {action === "Buy"
                  ? formatter.format(amount)
                  : formatter.format(prices[selectedAsset?.asset] * amount)}
              </p>
            </span>
          )}
        </div>
      </AmountBox>

      <MiniAmountBox className={"amount_box"}>
        <div className="label">
          <p>ROI:</p>
          <img
            src="./assets/misc/info.svg"
            alt=""
            className="error_inform"
            id="popcorn"
            style={{ display: "block" }}
            onClick={() => setShowRoiTooltip(!showRoiTooltip)}
          />
          {showRoiTooltip && (
            <ClickAwayListener onClickAway={() => setShowRoiTooltip(false)}>
              <div className="tooltip" id="tooltip" style={{ left: "1px" }}>
                {roiTooltipMessage}
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div className="wrapper" style={{ padding: "24px" }}>
          <input
            type="number"
            placeholder={roi + "%"}
            onChange={(e) => handleDuration(e)}
            disabled
          />
        </div>
      </MiniAmountBox>

      {/* duration */}
      <DropDownBox className="type_select">
        <div className="wrapper">
          <p className="label">Duration (Days):</p>
          <span className="content">
            <select name="options" onChange={handleDuration}>
              {maxiDaysArr.map((val, index) => (
                <option value={val}>
                  {val} {index === 0 ? "day" : "days"}
                </option>
              ))}
            </select>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="#5C6175"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </DropDownBox>

      {/* profits */}
      {compiledTotal && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              maxWidth: "180px",
              width: "100%",
              position: "relative",
            }}
          >
            {showCompiledToolTip && (
              <ClickAwayListener
                onClickAway={() => setShowCompiledToolTip(!showCompiledToolTip)}
              >
                <ToolTipContainer>
                  <div
                    className="tooltip"
                    id="tooltip"
                    style={{
                      bottom: "28px",
                      left: "5px",
                      width: "100%",
                    }}
                  >
                    {compiledToolTipMessage}
                  </div>
                </ToolTipContainer>
              </ClickAwayListener>
            )}
            <p
              style={{
                color: "#bac2de",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              Total returns after mining session
              <span style={{ position: "absolute", marginLeft: "4px" }}>
                <img
                  src="./assets/misc/info.svg"
                  alt=""
                  className="error_inform"
                  id="popcorn"
                  onClick={() => setShowCompiledToolTip(!showCompiledToolTip)}
                />
              </span>
            </p>
          </span>
          <span>
            <p
              style={{
                fontSize: "20px",
                color: "#5BDE4C",
                fontWeight: "600",
              }}
            >
              ~{compiledTotal} {miner?.symbol}
            </p>
          </span>
        </div>
      )}

      <FullButton onClick={() => navigate("/mining")}>
        <p>Start mining</p>
      </FullButton>
    </>
  );
};

export default Calculator;
